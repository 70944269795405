import styled from "@emotion/styled";
import { KeyValueDropdown } from "components/Dropdown/Atom";
import { LabeledInput } from "components/Input/Atom";
import { BaseModal } from "components/Modal/Atom";
import ModalPortal from "components/ModalPortal";
import LabeledTextarea from "components/Textarea/Atom/LabeledTextarea";
import { useAddESCCommand } from "hook/useAddECSCommand";
import { useEffect, useRef, useState } from "react";
import { colors } from "style/theme";
import BaseInstance from "instance/axios";
import { TicketRow } from ".";
import { FormatterProps } from "react-data-grid";
import { omitBy, isNil, isEmpty } from "lodash";
import { icons } from "modules/icons";

const ShippingInfoModal = ({
  formatterProps,
  isOpen,
  setIsOpen,
  loadRecords,
}: {
  formatterProps: FormatterProps<TicketRow, unknown>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadRecords: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const formData = useRef<Map<string, any>>(new Map());
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [deliveryTypeList, setDeliveryTypeList] = useState<
    { key: string; value: string }[]
  >([]);

  useAddESCCommand(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    setDeliveryTypeList([
      {
        key: "SELF",
        value: "직접출고",
      },
      {
        key: "PROVIDER",
        value: "탁송",
      },
    ]);
  }, []);

  const onSubmit = async () => {
    setButtonDisable(true);
    try {
      const data = {
        shippingInfoType: formData.current.get("shippingInfoType"),
        shippingInfoContact: formData.current.get("shippingInfoContact"),
        shippingInfoAddress: formData.current.get("shippingInfoAddress"),
        shippingInfoDescription: formData.current.get("shippingInfoDescription"),
        shippingInfoTime: new Date(),
      }

      await BaseInstance.ticketsTicketIdPut(formatterProps?.row.id, {
        shippingInfo: isEmpty(omitBy(data, isNil)) ? null : data,
      });
      loadRecords(true);
      setIsOpen(false);
      setButtonDisable(false);
    } catch (error) {
      alert("Error! please try again");
      setButtonDisable(false);
    }
  };

  return isOpen ? (
    <ModalPortal>
      <BaseModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContentWrapper>
          <ModalHeader>
            <h2>출고 정보 입력</h2>

            <div className="options">
              <div className="icon-box" onClick={() => setIsOpen(false)}>
                <icons.CloseIcon />
              </div>
            </div>
          </ModalHeader>

          <ModalFieldSet>
            <KeyValueDropdown
              initialKey={formatterProps?.row?.shippingInfo?.shippingInfoType}
              labelText="출고방법"
              items={deliveryTypeList}
              notifier={(value) => {
                formData.current?.set("shippingInfoType", value);
              }}
            />
            <LabeledInput
              initialValue={
                formatterProps?.row.shippingInfo?.shippingInfoContact
              }
              isFirstInput
              htmlFor="shippingInfoContact"
              labelText="연락처"
              // placeholder="연락처를 입력해 주세요"
              notifier={(value) => {
                formData.current?.set("shippingInfoContact", value);
              }}
            />
            <LabeledInput
              initialValue={
                formatterProps?.row.shippingInfo?.shippingInfoAddress
              }
              htmlFor="shippingInfoAddress"
              labelText="주소"
              // placeholder="주소를 입력해 주세요"
              notifier={(value) => {
                formData.current?.set("shippingInfoAddress", value);
              }}
            />
            <LabeledTextarea
              initialValue={
                formatterProps?.row.shippingInfo?.shippingInfoDescription
              }
              htmlFor="shippingInfoDescription"
              labelText="비고"
              placeholder="비고를 입력해 주세요"
              notifier={(value) => {
                formData.current?.set("shippingInfoDescription", value);
              }}
            />
          </ModalFieldSet>

          <ModalConfirmBtnBox>
            <button
              className="cancel_btn"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </button>
            <button onClick={onSubmit} type="submit" disabled={buttonDisable}>
              추가
            </button>
          </ModalConfirmBtnBox>
        </ModalContentWrapper>
      </BaseModal>
    </ModalPortal>
  ) : (
    <></>
  );
};

export default ShippingInfoModal;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  color: #eef0f4;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  word-wrap: break-word;

  & .options {
    & .icon-box {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 26.5px;

  gap: 20px;
`;

const ClientInfoBox = styled.div`
  display: flex;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  & button {
    width: 54px;
    height: 40px;
    background-color: ${colors.pointColorBlue};
    color: white;
    border-radius: 6px;

    &.cancel_btn {
      background-color: white;
      color: ${colors.graySix};
    }
  }
`;
