import styled from "@emotion/styled";
import { AxiosError } from "axios";
import { BaseModal } from "components/Modal/Atom";
import ModalPortal from "components/ModalPortal";
import dayjs from "dayjs";
import { useAddESCCommand } from "hook/useAddECSCommand";
import BaseInstance from "instance/axios";
import { icons } from "modules/icons";
import { useEffect, useRef, useState } from "react";
import { FormatterProps } from "react-data-grid";
import { colors } from "style/theme";
import { TicketRow } from ".";

const DetailModal = ({
  isOpen,
  setIsOpen,
  loadRecords,
  formatterProps,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadRecords: React.Dispatch<React.SetStateAction<boolean>>;
  formatterProps: FormatterProps<TicketRow, unknown>;
}) => {
  const formData = useRef<Map<string, any>>(new Map());
  const previousLogRef = useRef<any>(null);
  const [logData, setLogData] = useState<any>([]);
  useAddESCCommand(() => {
    setIsOpen(false);
  });

  const onSubmit = () => {
    const updatedRow = {
      ...formatterProps?.row,
      ...Object.fromEntries(formData.current?.entries()),
    };
  };

  useEffect(() => {
    if (formatterProps?.row?.id) {
      BaseInstance.ticketsTicketIdGet(Number(formatterProps?.row?.id), {
        params: { populate: "stagesInfo" },
      })

        .then((res: any) => {
          setLogData(res?.data?.stagesInfo[0].logs);
        })
        .catch((err: any) => {});
    }
  }, []);

  // pending일때 resume로 변경후 종료
  const forceStopOnPending = async () => {
    await BaseInstance.ticketTicketIdResumePut(formatterProps.row.id).then(
      (res) => {
        if (res.status == 200) {
          onClose();
        }
      }
    );
  };

  // process_pending 일때 processResume -> processFinish로 종료 후 onClose()
  const forceStopOnProcessPending = async () => {
    await BaseInstance.ticketTicketIdProcessResumePut(formatterProps.row.id).then(
      (res) => {
        if (res.status == 200) {
          forceStopOnProcessing();
        }
      })
  };

  // processing일때 processFinish로 종료 후 onClose()
  const forceStopOnProcessing = async () => {
    await BaseInstance.ticketTicketIdProcessFinishPut(
      formatterProps.row.id
    ).then((res) => {
      if (res.status == 200) {
        onClose();
      }
    });
  };

  const onStart = async () => {
    await BaseInstance.ticketTicketIdStartPut(formatterProps.row.id)
      .then((res) => {
        if (res.status == 200) {
          alert("정비가 시작되었습니다.");
          setIsOpen(false);
        }
      })
      .catch((err) => {});
  };

  const forceStopOnWaiting = async () => {
    await BaseInstance.ticketTicketIdStartPut(formatterProps.row.id)
      .then((res) => {
        if (res.status == 200) {
          BaseInstance.ticketTicketIdFinishPut(formatterProps.row.id);
          alert("정비가 종료되었습니다.");
          setIsOpen(false);
        }
      })
      .catch((err) => {});
  }

  const onClose = async () => {
    await BaseInstance.ticketTicketIdFinishPut(formatterProps.row?.id)
      .then((res) => {
        if (res.status == 200) {
          alert("정비가 종료되었습니다.");
          setIsOpen(false);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          if (
            logData[logData.length - 1]?.additional?.newStatus === "PENDING"
          ) {
            forceStopOnPending();
          } else if (
            logData[logData.length - 1]?.additional?.newStatus ===
            "PROCESS_PENDING"
          ) {
            forceStopOnProcessPending();
          } else if (
            logData[logData.length - 1]?.additional?.newStatus === "PROCESSING"
          ) {
            forceStopOnProcessing();
          } else if (
            // WAITING 상태
            logData.length === 0
          ) {
            forceStopOnWaiting();
          } else if (
            // IN_PROGRESS 상태
            logData[logData.length - 1]?.additional?.newStatus === "IN_PROGRESS"
          ) {
            onClose();
          }
        }
      });
  };

  const onRestart = async () => {
    BaseInstance.ticketTicketIdRestartPut(formatterProps.row.id).then((res) => {
      if (res.status == 200) {
        alert("재시작 되었습니다.");
        setIsOpen(false);
      }
    });
  };

  return isOpen ? (
    <ModalPortal>
      <BaseModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContentWrapper>
          <ModalHeader>
            <h2>상세 작업 로그</h2>

            <div className="options">
              <div className="icon-box" onClick={() => setIsOpen(false)}>
                <icons.CloseIcon />
              </div>
            </div>
          </ModalHeader>

          <ModalFieldSet>
            <div>
              {logData.map((v: any, index: number) => {
                let processName;
                let currentLog;

                if (v.additional?.process?.name?.length > 0) {
                  previousLogRef.current = v.additional?.process?.name;
                }
                processName = previousLogRef.current;

                if (
                  v.additional?.newStatus === "IN_PROGRESS" &&
                  v.additional?.oldStatus === "WAITING"
                ) {
                  currentLog = "정비 시작";
                } else if (
                  v.additional?.newStatus === "PROCESSING" &&
                  v.additional?.oldStatus === "IN_PROGRESS"
                ) {
                  currentLog = `${processName} 시작`;
                } else if (v.additional?.newStatus === "PROCESS_PENDING") {
                  currentLog = v.additional?.pendingReason?.name;
                } else if (
                  v.additional?.newStatus === "PENDING" &&
                  v.additional?.oldStatus === "IN_PROGRESS"
                ) {
                  currentLog = v.additional?.pendingReason?.name;
                } else if (
                  v.additional?.newStatus === "IN_PROGRESS" &&
                  v.additional?.oldStatus === "PENDING"
                ) {
                  currentLog = "작업 재개";
                } else if (
                  v.additional?.newStatus === "PROCESSING" &&
                  v.additional?.oldStatus === "PROCESS_PENDING"
                ) {
                  currentLog = "작업 재개";
                } else if (
                  v.additional?.newStatus === "IN_PROGRESS" &&
                  v.additional?.oldStatus === "PROCESSING"
                ) {
                  currentLog = `${processName} 완료`;
                } else if (v.additional?.newStatus === "DONE") {
                  currentLog = "정비 종료";
                } else if (
                  v.additional?.newStatus === "IN_PROGRESS" &&
                  v.additional?.oldStatus === "DONE"
                ) {
                  currentLog = "재시작";
                }
                return (
                  <div key={index}>
                    <p>
                      진행단계 : {currentLog}, 시작시간 :{" "}
                      {dayjs(v.createdAt).format("YY-MM-DD HH:mm:ss")}
                    </p>
                  </div>
                );
              })}
            </div>
          </ModalFieldSet>

          <ModalConfirmBtnBox>
            {logData[logData.length - 1]?.additional?.newStatus === "DONE" ? (
              <button onClick={onRestart}>{"재시작"}</button>
            ) : (
              <>
                <button className="cancel_btn" onClick={onStart}>
                  시작
                </button>
                <button onClick={onClose}>{"임의 종료"}</button>
              </>
            )}
          </ModalConfirmBtnBox>
        </ModalContentWrapper>
      </BaseModal>
    </ModalPortal>
  ) : (
    <></>
  );
};

export default DetailModal;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & h2 {
    color: #eef0f4;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 700;
    word-wrap: break-word;
  }

  & .options {
    & .icon-box {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 26.5px;
  gap: 20px;

  height: 20rem;
  overflow: auto;
  & p {
    font-size: 16px;
    color: #eef0f4;
  }
`;

const ClientInfoBox = styled.div`
  display: flex;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  & button {
    width: 54px;
    height: 40px;
    background-color: ${colors.pointColorBlue};
    color: white;
    border-radius: 6px;

    &.cancel_btn {
      background-color: white;
      color: ${colors.graySix};
    }
  }
`;
