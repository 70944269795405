import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  TicketResponse,
  TicketResponseStatus,
} from "@sizlcorp/mbk-api-document/dist/models";
import { AxiosResponse } from "axios";
import { BaseButton } from "components/Button/Atom";
import { BasePagination as Pagination } from "components/Pagination/Atom";
import dayjs from "dayjs";
import BaseInstance, { axiosInstance } from "instance/axios";
import { icons } from "modules/icons";
import printJS from "print-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "style/theme";
import useReceptionTable from "./ReceptionTable";
import RegisterModal from "./RegisterModal";

export interface TicketRow {
  id: number;
  stageName: string;
  carModel?: string;
  plateCode: string;
  stepStatus: string;
  customerName: string;
  mobile: string;
  vinCode?: string;
  createdAt: string;
  context?: string;
  status?: string;
  deliveryState?: "DOING" | "DONE" | "TODO";
  siteId: number;
  createUser?: { name: string; mobile: string };
  shippingInfo?: {
    shippingInfoAddress: string;
    shippingInfoContact: string;
    shippingInfoDescription: string;
    shippingInfoType: "SELF" | "PROVIDER";
  };
  isStagesDoneMessageSent?: boolean;
}

type CustomTicketResponse = TicketResponse & { createdAt: string } & {
  deliveryState: string;
  isStagesDoneMessageSent: boolean;
} & {
  shippingInfo: {
    shippingInfoAddress: string;
    shippingInfoContact: string;
    shippingInfoDescription: string;
    shippingInfoType: "SELF" | "PROVIDER";
  };
  createUser: {
    name: string;
    mobile: string;
  };
  pendingReason: string;
};

type ReceptionsProps = {
  progress: string;
};

function statusDisplay(status?: TicketResponseStatus): string {
  if (!status) return "";
  const statusMap: {
    [key: string]: string;
  } = {
    TODO: "대기",
    DOING: "진행중",
    DONE: "완료",
  };
  if (status.label === "DONE") return statusMap[status.label];
  if (status.label === "TODO") return statusMap[status.label];
  return `${statusMap[status?.label]} (${status?.currentStageNumber}/${
    status?.totalStageNumber
  })`;
}
function Reception({ progress }: ReceptionsProps) {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  // const onHandleMessage = () => { };

  const pageSizes = [5, 10, 15];
  const [tickets, setTickets] = useState<Array<TicketRow>>([]); // code changes for ticket #564 by TBI

  const [isListSizeDropdownOpen, setIsListSizeDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [perPageRows, setPerPageRows] = useState(5); // code changes for ticket #564 by TBI
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentFilter, setCurrentFilter] = useState("");
  // code changes for ticket #564 by TBI
  const [activePage, setActivePage] = useState<number>(currentPage);
  const [shortColumn, setShortColumn] = useState<any>({
    columnKey: "createdAt",
    direction: "DESC",
  });
  const [refresh, setRefresh] = useState<{ type: "YES" | "NO" }>({
    type: "NO",
  });
  const navigate = useNavigate();
  const init = async (
    page: number,
    pageSize: number,
    searchValue: string,
    filterValue: string = "ALL"
  ) => {
    const newLocal = filterValue === "ALL";
    const requestQuery = newLocal
      ? JSON.stringify({
          siteId: Number(localStorage.getItem("siteId")),
        })
      : JSON.stringify({
          siteId: Number(localStorage.getItem("siteId")),
          stepStatus: filterValue,
        });

    await BaseInstance.ticketsGet(
      {},
      {
        params: {
          query: requestQuery,
          page,
          pageSize,
          populate: [
            "stage",
            "site",
            "workbay",
            "carModel",
            "status",
            "createUser",
            "pendingReason",
          ],
          sort: `${shortColumn.direction === "DESC" ? "-" : ""}${
            shortColumn.columnKey
          }`,
          search: searchValue,
          searchFields: [
            "plateCode",
            "customerName",
            "mobile",
            "vinCode",
            "carModel",
          ],
        },
      }
    )
      .then(({ data }: any) => {
        setTotalData(data?.total);
        const tickets: CustomTicketResponse[] = data?.rows;

        setTickets(
          tickets?.map((x: CustomTicketResponse) => {
            return {
              id: x.id,
              stageName: x.pendingReason
                ? `지연(${x.pendingReason})`
                : x.stage?.name ?? "-",
              plateCode: x.plateCode,
              stepStatus: x.stepStatus,
              customerName: x.customerName,
              mobile: x.mobile,
              vinCode: x.vinCode,
              createdAt: dayjs(x?.createdAt).format("YY-MM-DD HH:mm"),
              context: x.context,
              carModel: x.carModel,
              status: statusDisplay(x.status),
              deliveryState: x?.status?.label,
              siteId: x.siteId,
              shippingInfo: x.shippingInfo,
              createUserName:
                x?.createUser?.name + " (" + x?.createUser?.mobile + ")",
              isStagesDoneMessageSent: x.isStagesDoneMessageSent,
              parkingLocation: x.parkingLocation,
            };
          })
        );
      })
      .catch((e) => {
        console.log("Tickets Get Error ::: ", e);
      });
  };

  useEffect(() => {
    init(currentPage, perPageRows, searchKeyword, currentFilter);
  }, [shortColumn]);

  const { selectedRows, Table: ReceptionTable } = useReceptionTable(
    tickets,
    currentPage,
    {
      refreshTickets(val) {
        setRefresh(val);
      },
    },
    setShortColumn
  );

  // code changes for ticket #564 by TBI
  useEffect(() => {
    if (refresh.type === "YES") {
      setActivePage(1);
      setCurrentPage(1);

      init(1, perPageRows, searchKeyword);
    }
  }, [perPageRows, refresh, searchKeyword]);

  useEffect(() => {
    init(1, perPageRows, searchKeyword, progress);
    if (progress) {
      setCurrentFilter(progress);
    }
  }, [perPageRows, searchKeyword, progress]);
  return (
    <Container>
      <NewOrderBar>
        <BaseButton onClick={() => setIsRegisterOpen(true)}>
          신규 접수 <icons.PlusIcon />
        </BaseButton>
        <BaseButton
          onClick={(e) => {
            const values = selectedRows.values();
            const ticketIds = Array.from(values);
            if (selectedRows && selectedRows.size > 0) {
              axiosInstance
                .post(
                  "/ticket/issue",
                  {
                    ticketId: ticketIds,
                  },
                  {
                    responseType: "arraybuffer",
                  }
                )
                .then(async (response: AxiosResponse<any>) => {
                  const pdfFile = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const pdfUrl = URL.createObjectURL(pdfFile);
                  printJS(pdfUrl);
                });
            } else {
              e.preventDefault();
            }
          }}
          additionalCSS={
            selectedRows && selectedRows.size > 0
              ? PrintBtnCSS
              : DisabledPrintBtnCSS
          }
        >
          QR 라벨 출력 <icons.PrintIcon />
        </BaseButton>
        <BaseButton
          onClick={(e) => {
            const ticketsId = Array.from(selectedRows.values());
            if (selectedRows.size == 1) {
              window.open(
                `https://mbk-customer.vercel.app/?ticketId=${ticketsId}`
              );
            }
          }}
          additionalCSS={
            selectedRows && selectedRows.size == 1
              ? PrintBtnCSS
              : DisabledPrintBtnCSS
          }
        >
          랜딩 페이지로 이동
        </BaseButton>

        {/* <BaseButton onClick={onHandleMessage} additionalCSS={MessageBtnCSS}>
          메세지 전송 <icons.EmailIcon />
        </BaseButton> */}
      </NewOrderBar>

      <FilterBox>
        <BaseButton
          additionalCSS={DisplayFilterBtnCSS}
          onClick={() => setIsListSizeDropdownOpen((prev) => !prev)}
        >
          <p>
            Display <span>{perPageRows} Tickets</span>
          </p>
          <icons.ArrowDownIcon />

          <PageSizeDropdown
            isOpen={isListSizeDropdownOpen}
            onClick={(e) => e.stopPropagation()}
          >
            {pageSizes.map((value) => (
              <PageSize
                key={value}
                onClick={() => {
                  // code changes for ticket #564
                  setPerPageRows(value);

                  totalData && value
                    ? setCurrentPage(
                        Math.ceil(totalData / value) < currentPage
                          ? Math.ceil(totalData / value)
                          : currentPage
                      )
                    : setCurrentPage(currentPage);
                  totalData && value
                    ? setActivePage(
                        Math.ceil(totalData / value) < currentPage
                          ? Math.ceil(totalData / value)
                          : currentPage
                      )
                    : setActivePage(currentPage);
                  init(
                    totalData && value
                      ? Math.ceil(totalData / value) < currentPage
                        ? Math.ceil(totalData / value)
                        : currentPage
                      : currentPage,
                    value,
                    searchKeyword
                  );
                  setIsListSizeDropdownOpen(true);
                }}
              >
                {value} Tickets
              </PageSize>
            ))}
          </PageSizeDropdown>
        </BaseButton>
        <SearchBox>
          <SearchArea>
            <icons.SearchIcon />
            <SearchInput
              placeholder="Search here..."
              onChange={({ target: { value } }) => {
                setSearchKeyword(value);
                init(currentPage, perPageRows, value, currentFilter);
              }}
            />
          </SearchArea>
          <SearchDescription>
            차량번호, 고객 이름, 고객 연락처, VIN, 모델명
          </SearchDescription>
        </SearchBox>
      </FilterBox>

      <TableBox>
        <ReceptionTable />
      </TableBox>

      <PaginationBox>
        {totalData ? (
          // code changes for ticket #564
          <Pagination
            activePage={activePage}
            totalData={totalData}
            countPerPage={perPageRows}
            currentPg={currentPage}
            notifier={(_currentPage) => {
              init(_currentPage, perPageRows, searchKeyword, currentFilter);
              setCurrentPage(_currentPage);
              setActivePage(_currentPage);
            }}
          />
        ) : (
          // end code changes for ticket #564

          ""
        )}
      </PaginationBox>
      <RegisterModal
        isOpen={isRegisterOpen}
        setIsOpen={(value) => setIsRegisterOpen(value)}
        loadRecords={(value) => {
          return value
            ? (setActivePage(currentPage),
              init(currentPage, perPageRows, searchKeyword, currentFilter))
            : "";
        }}
      />
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
  height: auto;
  background-color: ${colors.indigo};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1440px) {
  }
`;

const NewOrderBar = styled.div`
  margin-top: 24px;
  padding: 0 24px;
  display: flex;
  gap: 24px;
  align-items: center;
`;

const PrintBtnCSS = css`
  width: 120px;
  background-color: transparent;
  border: 1px solid #bac4d1;
  color: #bac4d1;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

const DisabledPrintBtnCSS = css`
  ${PrintBtnCSS}
  background-color: #e0e0e0;
  border-color: #a9a9a9;
  color: #a9a9a9;
  cursor: not-allowed;
`;

const MessageBtnCSS = css`
  ${PrintBtnCSS}
  background-color: ${colors.pointColorBlue};
  border: none;
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 24px;
  @media (max-width: 575px) {
    gap: 20px;
  }
`;
const DisplayFilterBtnCSS = css`
  background-color: ${colors.pointColorGray};
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;

  & p {
    & span {
      color: #eef0f4;
      font-size: 14px;
      font-family: Roboto;
      font-weight: 700;
      word-wrap: break-word;
    }
  }
`;

const PageSizeDropdown = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  min-height: 100%;
  height: auto;
  left: 0;
  top: 110%;
  z-index: var(--zIndex-1st);
  background-color: ${colors.pointColorGray};
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transition: all 0.2s ease-in;
  transform-origin: top;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    `}
`;
const PageSize = styled.li`
  width: 100%;
  height: auto;
  display: flex;
`;

const SearchBox = styled.div`
  width: 360px;
  height: 40px;
  padding: 10px;
  background: #3b4758;
  color: #bac4d1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  @media (max-width: 575px) {
    width: 100%;
  }
`;
const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  background: #3b4758;
  color: #bac4d1;
  border: none;

  &::-webkit-input-placeholder {
    color: #bac4d1;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word;
  }
`;

const TableBox = styled.div`
  margin-top: 20px;
  // @media (max-width: 1440px) {
  //   height: 300px;
  //   overflow: scroll;
  // }
`;

const PaginationBox = styled.div`
  margin: 24px 0;
`;

const SearchArea = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 240px;
`;
const SearchDescription = styled.div`
  width: 120px;
  font-size: 11px;
`;

export default Reception;
