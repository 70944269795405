import { Box } from "@mantine/core";

interface FileMainProps {
  children: React.ReactNode;
  styles?: React.CSSProperties;
}

export const FileMain = ({ children }: FileMainProps) => {
  return (
    <Box
      style={{
        width: "40rem",
        height: "auto",
        border: "1px solid var(--color-gray-300)",
        borderRadius: "12px",
        padding: "20px 24px",
        backgroundColor: "#1d232c",
      }}
    >
      {children}
    </Box>
  );
};
