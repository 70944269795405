import styled from "@emotion/styled";
import { colors } from "style/theme";
import { LabeledInput } from "components/Input/Atom";
import { BaseDropdown } from "components/Dropdown/Atom";
import BaseInstance from "instance/axios";
import { useState } from "react";
import { KeyValueDropdown } from "components/Dropdown/Atom";
import { authEnum } from "../../../config/auth";

function InformationManagement({ getUserData }: { getUserData: any }) {
  const positionFilterList = ["TECHNICIAN", "ADMIN", "SA"];
  const workbayList: { key: number; value: string }[] = [
    { key: 1, value: "워크베이1" },
  ];
  const formData = getUserData;
  const [error, setErrors] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [loader, setloader] = useState<boolean>(false);

  const validation = () => {
    if (
      formData.current.get("password") !=
      formData.current.get("confirmPassword")
    ) {
      setErrors("Password and Confirm password is not matching.");
      return false;
    } else if (
      formData.current.get("email") != formData.current.get("old_email") && !formData.current.get("password")
    ) {
      setErrors("Password is required.");
      return false;
    } else if (
      !formData.current.get("password") || !formData.current.get("confirmPassword")
    ) {
      setErrors("Password or Confirm password is required.");
      return false;
    } else {
      return true;
    }
  };

  const onsubmit = async () => {
    setErrors("");

    if (validation()) {
      setloader(true)
      await BaseInstance.usersUserIdPut(
        Number(formData.current.get("id")),
        {
          name: formData.current.get("name"),
          email: formData.current.get("email"),
          password: formData.current.get("password"),
          mobile: formData.current.get("mobile"),
          exposedMobile: formData.current.get("exposedMobile"),
          defaultWorkbayId:
            formData.current.get("defaultWorkbayId") &&
              !isNaN(formData.current.get("defaultWorkbayId"))
              ? formData.current.get("defaultWorkbayId")
              : 1,
          role: formData.current.get("role"),
          siteId: localStorage.getItem('siteId') ? Number(localStorage.getItem('siteId')) : 1,
        },
        {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem(authEnum.authToken)}`,
          },
        }
      )
        .then(async ({ data }: any) => {
          if (formData.current.get("password") && formData.current.get("old_email") != formData.current.get("email")) {
            await BaseInstance.authLoginPost({
              identifier: formData.current.get("email"),
              password: formData.current.get("password")
            }, {
              headers: {
                'Authorization': undefined
              }
            }).then(({ data }: any) => {
              localStorage.setItem('authToken', data.token)
            });
          }

          setloader(false);
          setSuccess("Information updated successfully.");
        })
        .catch((e) => {
          setloader(false);
          setErrors("Information not updated! Something went wrong.");
        })
        .finally(() => {
          setTimeout(() => {
            setErrors("");
            setSuccess(""); // *** If you want to clear the error message as well
          }, 3000); // *** as the normal message
        });
    }
  };

  return (
    <>
      <InputBox>
        <LabeledInput
          isFirstInput
          htmlFor="email"
          labelText="사용자명 (이메일)"
          initialValue={formData.current.get("email")}
          notifier={(value) => {
            formData.current?.set("old_email", formData.current.get("email"));
            formData.current?.set("email", value);
          }}
        />
        <LabeledInput
          htmlFor="name"
          labelText="이름"
          initialValue={formData.current.get("name")}
          notifier={(value) => {
            formData.current?.set("name", value);
          }}
        />
        <LabeledInput
          htmlFor="mobile"
          labelText="핸드폰 번호"
          initialValue={formData.current.get("mobile")}
          notifier={(value) => {
            formData.current?.set("mobile", value);
          }}
        />
        <LabeledInput
          htmlFor="exposedMobile"
          labelText="노출가능한 핸드폰 번호"
          initialValue={formData.current.get("exposedMobile") ? formData.current.get("exposedMobile") : ''}
          notifier={(value) => {
            formData.current?.set("exposedMobile", value);
          }}
        />
        <BaseDropdown
          labelText="포지션"
          filterList={positionFilterList}
          initialValue={formData.current.get("role")}
          notifier={(value) => {
            formData.current?.set("role", value);
          }}
        />
        <KeyValueDropdown
          labelText="워크베이"
          items={workbayList}
          initialKey={formData.current.get("defaultWorkbayId")}
          notifier={(value) => {
            formData.current?.set("defaultWorkbayId", value);
          }}
        />

        <LabeledInput
          htmlFor="password"
          labelText="패스워드"
          notifier={(value) => {
            formData.current?.set("password", value);
          }}
        />
        <LabeledInput
          htmlFor="confirmPassword"
          labelText="패스워드 확인"
          notifier={(value) => {
            formData.current?.set("confirmPassword", value);
          }}
        />
      </InputBox>
      <FormConfirmBtnBox>
        {success ? <div className="success-in-green">{success}</div> : ""}
        {error ? <div className="error-in-red">{error}</div> : ""}
        <button className="cancel_btn">취소</button>
        <button onClick={onsubmit} disabled={loader}>{loader ? "..." : "수정"}</button>
      </FormConfirmBtnBox>
    </>
  );
}

const FormConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;

  & button {
    width: 54px;
    height: 40px;
    background-color: ${colors.pointColorBlue};
    color: white;
    border-radius: 6px;

    &.cancel_btn {
      background-color: white;
      color: ${colors.graySix};
    }
  }
  & div.error-in-red {
    color: indianred;
  }
  & div.success-in-green {
    color: green;
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  float: left;
  /* margin-top: 65px; */
`;
export default InformationManagement;
