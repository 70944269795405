import { icons } from "./icons";

export const menuList = [
  {
    id: "/dashboard",
    name: "Dashboards",
    contentTitle: "작업장 전체 현황",
    icon: <icons.DashboardIcon />,
  },
  {
    id: "/reception",
    name: "접수건",
    contentTitle: "접수건 관리",
    icon: <icons.ReceptionsIcon />,
  },
  {
    id: "/users",
    name: "사용자 관리",
    contentTitle: "사용자 관리",
    icon: <icons.UsersIcon />,
  },
  {
    id: "/informationManagement",
    name: "내 정보 관리",
    contentTitle: "내 정보 관리",
    icon: <icons.UsersIcon />,
  },
  {
    id: "/siteConfig",
    name: "센터 관리",
    contentTitle: "센터 관리",
    icon: <icons.ProjectsIcon />,
  }
] as const;

type MenuItem = (typeof menuList)[number];

export type MenuId = MenuItem["id"];
export type MenuName = MenuItem["name"];
export type ContentTitle = MenuItem["contentTitle"];
