import styled from "@emotion/styled";
import { BaseButton } from "components/Button/Atom";
import { BasePagination as Pagination } from "components/Pagination/Atom";
import { colors } from "style/theme";
import { icons } from "modules/icons";
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import BaseInstance, { axiosInstance } from "instance/axios";
import { UserResponse } from "@sizlcorp/mbk-api-document/dist/models";
import RegisterModal from "./RegisterModal";
import useUsersTable from "./UsersTable";
import dayjs from "dayjs";

export interface UserRow {
  id: number;
  email: string;
  role: string;
  mobile?: string;
  siteId: number;
  roleName?: string;
  workbayName?: string;
  siteName?: string;
  createdAt: string;
  options?: "";
  name?: string;
  workbayId?: any;
  exposedMobile?: string;
}

export type CustomUserResponse = UserResponse & {
  createdAt?: string,
  site?: {
    name: string
  },
  workbay?: {
    name: string
  }
}

function Users() {
  const pageSizes = [5, 10, 15];
  const [users, setUsers] = useState<Array<UserRow>>([]); // code changes for ticket #564 by TBI

  const [isListSizeDropdownOpen, setIsListSizeDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [perPageRows, setPerPageRows] = useState(5); // code changes for ticket #564 by TBI
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentFilter, setCurrentFilter] = useState("");
  // code changes for ticket #564 by TBI
  const [activePage, setActivePage] = useState<number>(currentPage);
  const [shortColumn, setShortColumn] = useState<any>({ columnKey: "createdAt", direction: 'DESC' });
  const [refresh, setRefresh] = useState<{ type: "YES" | "NO" }>({
    type: "NO",
  });
  const [isOrderFilterOpen, setIsOrderFilterOpen] = useState(false);

  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  // const onHandleMessage = () => { };

  const init = async (
    page: number,
    pageSize: number,
    searchValue: string,
    filterValue: string = "",
  ) => {
    const newLocal = filterValue === "ALL";
    const requestQuery = newLocal ? JSON.stringify({
      siteId: Number(localStorage.getItem("siteId")),
    }) : JSON.stringify({
      siteId: Number(localStorage.getItem("siteId")),
    });

    await BaseInstance.usersGet(
      {},
      {
        params: {
          query: requestQuery,
          page,
          pageSize,
          populate: [
            "site",
          ],
          sort: `${shortColumn.direction === "DESC" ? "-" : ""}${shortColumn.columnKey}`,
          search: searchValue,
        },
      }
    )
      .then(({ data }: any) => {
        setTotalData(data.total);
        const users: CustomUserResponse[] = data.rows;
        console.log("Users Get ::: ", users)
        setUsers(
          users.map((x: CustomUserResponse) => {
            return {
              id: x.id,
              email: x.email,
              role: x.role,
              siteId: x.siteId,
              name: x.name,
              roleName: x.role,
              mobile: x.mobile,
              workbayName: x.workbay?.name,
              workbayId: x.workbay?.id,
              siteName: x.site?.name,
              exposedMobile: x.exposedMobile,
              createdAt: dayjs(x?.createdAt).format("YY-MM-DD HH:mm"),
            };
          })
        );
      })
      .catch((e) => {
        console.log("Users Get Error ::: ", e);
      });
  };

  useEffect(() => {
    init(currentPage, perPageRows, searchKeyword, currentFilter);
  }, [shortColumn])

  const { selectedRows, Table: UserTable } = useUsersTable(
    users,
    currentPage,
    {
      refreshUsers(val) {
        setRefresh(val);
      },
    },
    setShortColumn
  );

  useEffect(() => {
    if (refresh.type === "YES") {
      setActivePage(1);
      setCurrentPage(1);
      init(1, perPageRows, searchKeyword);
    }
    console.log(refresh);

  }, [perPageRows, refresh, searchKeyword]);

  return (
    <Container>
      <RegisterBtnBox>
        <BaseButton onClick={() => setIsRegisterOpen(true)}>
          신규 등록 <icons.PlusIcon />
        </BaseButton>
      </RegisterBtnBox>

      <FilterBox>
        <BaseButton additionalCSS={DisplayFilterBtnCSS} onClick={() => setIsOrderFilterOpen((prev) => !prev)}>
          <p>
            Display <span>{perPageRows} users</span>
          </p>
          <icons.ArrowDownIcon />

          <ListSizeDropdown
            isOpen={isListSizeDropdownOpen}
            onClick={(e) => e.stopPropagation()}
          >
            {pageSizes.map((value) => (
              <UserList
                key={value}
                onClick={() => {
                  setPerPageRows(value);

                  totalData && value
                    ? setCurrentPage(
                      Math.ceil(totalData / value) < currentPage
                        ? Math.ceil(totalData / value)
                        : currentPage
                    )
                    : setCurrentPage(currentPage);
                  totalData && value
                    ? setActivePage(
                      Math.ceil(totalData / value) < currentPage
                        ? Math.ceil(totalData / value)
                        : currentPage
                    )
                    : setActivePage(currentPage);
                  init(
                    totalData && value
                      ? Math.ceil(totalData / value) < currentPage
                        ? Math.ceil(totalData / value)
                        : currentPage
                      : currentPage,
                    value,
                    searchKeyword
                  );
                  setIsListSizeDropdownOpen(true);
                }}
              >
                {value} Users
              </UserList>
            ))}
          </ListSizeDropdown>
        </BaseButton>

        <SearchBox>
          <icons.SearchIcon />
          <SearchInput placeholder="Search here..." />
        </SearchBox>
      </FilterBox>

      <TableBox>
        <UserTable />
      </TableBox>

      <PaginationBox>
        {totalData ? (
          <Pagination
            activePage={activePage}
            totalData={totalData}
            countPerPage={perPageRows}
            currentPg={currentPage}
            notifier={(_currentPage) => {
              init(_currentPage, perPageRows, searchKeyword, currentFilter);
              setCurrentPage(_currentPage);
              setActivePage(_currentPage);
            }}
          />
        ) : (
          // end code changes for ticket #564

          ""
        )}
      </PaginationBox>

      <RegisterModal isOpen={isRegisterOpen} setIsOpen={setIsRegisterOpen} />
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
  height: auto;
  background-color: ${colors.indigo};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

const RegisterBtnBox = styled.div`
  margin-top: 24px;
  padding: 0 24px;
`;
const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 24px;
  @media (max-width: 575px) {
    gap: 20px;
  }
`;
const DisplayFilterBtnCSS = css`
  background-color: ${colors.pointColorGray};
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;

  & p {
    & span {
      color: #eef0f4;
      font-size: 14px;
      font-family: Roboto;
      font-weight: 700;
      word-wrap: break-word;
    }
  }
`;

const ListSizeDropdown = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  min-height: 100%;
  height: auto;
  left: 0;
  top: 110%;
  z-index: var(--zIndex-1st);
  background-color: ${colors.pointColorGray};
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transition: all 0.2s ease-in;
  transform-origin: top;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    `}
`;
const UserList = styled.li`
  width: 100%;
  height: auto;
  display: flex;
`;

const SearchBox = styled.div`
  width: 240px;
  height: 40px;
  padding: 10px;
  background: #3b4758;
  color: #bac4d1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  // @media (max-width: 575px) {
  //   width: 100%;
  // }
`;
const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  background: #3b4758;
  color: #bac4d1;
  border: none;

  &::-webkit-input-placeholder {
    color: #bac4d1;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word;
  }
`;

const TableBox = styled.div`
  margin-top: 20px;
`;

const PaginationBox = styled.div`
  margin: 24px 0;
`;

export default Users;
