import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LabeledInput } from "components/Input/Atom";
import { BaseModal } from "components/Modal/Atom";
import ModalPortal from "components/ModalPortal";
import LabeledTextarea from "components/Textarea/Atom/LabeledTextarea";
import { useAddESCCommand } from "hook/useAddECSCommand";
import { icons } from "modules/icons";
import { useRef, useState } from "react";
import { colors } from "style/theme";
import BaseInstance from "instance/axios";

const RegisterModal = ({
  isOpen,
  setIsOpen,
  loadRecords,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadRecords: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const formData = useRef<Map<string, any>>(new Map());
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  useAddESCCommand(() => {
    setIsOpen(false);
  });

  const onSubmit = async () => {
    setButtonDisable(true);
    try {
      if (
        window.confirm('접수건이 등록되면, 고객에게 메시지가 전송됩니다 \n접수건을 등록하시겠습니까?')
      ) {
        await BaseInstance.ticketsPost({
          plateCode: formData.current.get("plateCode"),
          customerName: formData.current.get("customerName"),
          mobile: formData.current.get("mobile"),
          siteId: Number(localStorage.getItem("siteId")),
          vinCode: formData.current.get("vinCode"),
          context: formData.current.get("context"),
          carModel: formData.current.get("carModel"),
        });

        loadRecords(true);
        setIsOpen(false);
        setButtonDisable(false);
      } else {
        setButtonDisable(false);
      }

    } catch (error) {
      alert("Error! please try again");
      setButtonDisable(false);
      console.log("Ticket Create Error ::: ", error);
    }
  };

  return isOpen ? (
    <ModalPortal>
      <BaseModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContentWrapper>
          <ModalHeader>
            <h2>접수 추가</h2>

            <div className="options">
              <div className="icon-box" onClick={() => setIsOpen(false)}>
                <icons.CloseIcon />
              </div>
            </div>
          </ModalHeader>

          <ModalFieldSet>
            <LabeledInput
              isFirstInput
              htmlFor="plateCode"
              labelText="차량번호"
              notifier={(value) => {
                formData.current?.set("plateCode", value);
              }}
            />

            <LabeledInput
              htmlFor="carModel"
              labelText="모델"
              notifier={(value) => {
                formData.current?.set("carModel", value);
              }}
            />

            <LabeledInput
              htmlFor="vinCode"
              labelText="VIN"
              notifier={(value) => {
                formData.current?.set("vinCode", value);
              }}
            />

            <ClientInfoBox>
              <LabeledInput
                htmlFor="customerName"
                labelText="고객명"
                additionalCSS={css`
                  width: 100%;
                `}
                notifier={(value) => {
                  formData.current?.set("customerName", value);
                }}
              />
              <LabeledInput
                htmlFor="mobile"
                labelText="전화번호"
                additionalCSS={css`
                  width: 100%;
                `}
                notifier={(value) => {
                  formData.current?.set("mobile", value);
                }}
              />
            </ClientInfoBox>

            <LabeledTextarea
              htmlFor="context"
              labelText="비고"
              placeholder="Type event details"
              notifier={(value) => {
                formData.current?.set("context", value);
              }}
            />
          </ModalFieldSet>

          <ModalConfirmBtnBox>
            <button
              className="cancel_btn"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </button>
            <button onClick={onSubmit} disabled={buttonDisable}>
              추가
            </button>
          </ModalConfirmBtnBox>
        </ModalContentWrapper>
      </BaseModal>
    </ModalPortal>
  ) : (
    <></>
  );
};

export default RegisterModal;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  color: #eef0f4;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  word-wrap: break-word;

  & .options {
    & .icon-box {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 26.5px;

  gap: 20px;
`;

const ClientInfoBox = styled.div`
  display: flex;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  & button {
    width: 54px;
    height: 40px;
    background-color: ${colors.pointColorBlue};
    color: white;
    border-radius: 6px;

    &.cancel_btn {
      background-color: white;
      color: ${colors.graySix};
    }
  }
`;
