import styled from "@emotion/styled";
import { WorkbayResponse } from "@sizlcorp/mbk-api-document/dist/models";
import { KeyValueDropdown } from "components/Dropdown/Atom";
import { LabeledInput } from "components/Input/Atom";
import { BaseModal } from "components/Modal/Atom";
import ModalPortal from "components/ModalPortal";
import { useAddESCCommand } from "hook/useAddECSCommand";
import BaseInstance from "instance/axios";
import { icons } from "modules/icons";
import { useEffect, useRef, useState } from "react";
import { colors } from "style/theme";

const RegisterModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const formData = useRef<Map<string, any>>(new Map());
  const [workbays, setWorkbays] = useState<{ key: number, value: string }[]>([]);
  const roles: { key: string, value: string }[] = [
    { key: 'TECHNICIAN', value: '테크니션' },
    { key: 'ADVISER', value: '어드바이저' }
  ]

  useEffect(() => {
    BaseInstance.workbaysGet({}).then((res) => {
      if (!res.data.rows || res.data.rows.length === 0) return;
      setWorkbays(res.data.rows.map((model: WorkbayResponse) => {
        return {
          key: model.id,
          value: model.name as string
        }
      }))
    })
  }, []);

  const onSubmit = async () => {
    await BaseInstance.authSignupPost({
      siteId: Number(localStorage.getItem('siteId')),
      email: formData.current?.get("email"),
      name: formData.current?.get("name"),
      role: formData.current?.get("role"),
      password: formData.current?.get("password"),
      exposedMobile: formData.current?.get("exposedMobile"),
      // defaultWorkbayId: formData.current?.get("defaultWorkbayId"),
    }, {
      headers: {
        'Authorization': undefined
      }
    });
    setIsOpen(false)
  };

  useAddESCCommand(() => setIsOpen(false));

  return isOpen ? (
    <ModalPortal>
      <BaseModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContentWrapper>
          <ModalHeader>
            <h2>사용자 추가</h2>

            <div className="options">
              <div className="icon-box" onClick={() => setIsOpen(false)}>
                <icons.CloseIcon />
              </div>
            </div>
          </ModalHeader>

          <ModalFieldSet>
            <LabeledInput
              isFirstInput
              htmlFor="email"
              labelText="사용자명(이메일)"
              notifier={(value) => {
                formData.current?.set("email", value);
              }}
            />

            <LabeledInput
              htmlFor="name"
              labelText="이름"
              notifier={(value) => {
                formData.current?.set("name", value);
              }}
            />

            <LabeledInput
              htmlFor="mobile"
              labelText="핸드폰번호"
              notifier={(value) => {
                formData.current?.set("mobile", value);
              }}
            />
            <LabeledInput
              htmlFor="exposedMobile"
              labelText="노출모바일"
              notifier={(value) => {
                formData.current?.set("exposedMobile", value);
              }}
            />
            <KeyValueDropdown
              labelText="포지션"
              items={roles}
              notifier={(value) => {
                formData.current?.set("role", value);
              }}
            />

            {/* <KeyValueDropdown
              labelText="워크베이"
              items={workbays}
              notifier={(value) => {
                formData.current?.set("defaultWorkbayId", value);
              }}
            /> */}


            <LabeledInput
              htmlFor="password"
              labelText="패스워드"
              notifier={(value) => {
                formData.current?.set("password", value);
              }}
            />

            <LabeledInput
              htmlFor="password-check"
              labelText="패스워드 확인"
              notifier={(value) => {
                formData.current?.set("passwordCheck", value);
              }}
            />
          </ModalFieldSet>

          <ModalConfirmBtnBox>
            <button onClick={onSubmit}>추가</button>
          </ModalConfirmBtnBox>
        </ModalContentWrapper>
      </BaseModal>
    </ModalPortal>
  ) : (
    <></>
  );
};

export default RegisterModal;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & h2 {
    color: #eef0f4;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    word-wrap: break-word;
  }

  & .options {
    & .icon-box {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 26.5px;

  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;

  & button {
    width: 54px;
    height: 40px;
    background-color: ${colors.pointColorBlue};
    color: white;
    border-radius: 6px;
  }
`;
