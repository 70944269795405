import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { authEnum } from "config/auth";
import useDetectClose from "hook/useDetectClose";
import BaseInstance from "instance/axios";
import { menuList } from "modules/sidebar";
import { PropsWithChildren, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { colors } from "style/theme";

function DropDown({ children }: PropsWithChildren) {
  const location = useLocation();
  const [menus, setMenus] = useState(menuList);
  const [isOpen, ref, removeHandler] = useDetectClose(false);

  useEffect(() => {
    getInfo();
  }, [location]);

  const getInfo = async () => {
    await BaseInstance.whoamiGet({
      headers: {
        Authorization: `Bearer ${localStorage.getItem(authEnum.authToken)}`,
      },
    }).then(({ data }: any) => {
      if (data?.role !== "ADMIN") {
        const filteredMenus: any = menuList.filter((object) => {
          return object.id !== "/users";
        });
        setMenus(filteredMenus);
      }
    });
  };

  return (
    <Wrapper>
      <DropdownContainer>
        <DropdownButton onClick={removeHandler} ref={ref}>
          {children}
          <Menu $isDropped={isOpen}>
            <Ul>
              {menus.map((menu) => (
                <NavbarLink
                  key={menu.id}
                  isSelected={menu.id === location.pathname}
                  to={menu.id}
                >
                  {menu.icon}
                  {menu.name}
                </NavbarLink>
              ))}
            </Ul>
          </Menu>
        </DropdownButton>
      </DropdownContainer>
    </Wrapper>
  );
}

interface DropDownProps {
  $isDropped: boolean;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  @media (min-width: 431px) {
    display: none;
  }
`;

const DropdownContainer = styled.div`
  text-align: center;
`;

const DropdownButton = styled.div`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
`;

const Menu = styled.div<DropDownProps>`
  position: absolute;
  top: 50px;
  left: -16px;
  width: 160px;
  text-align: center;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  background-color: white;

  opacity: 0;
  visibility: hidden;
  transform: translate(0, -20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 9;

  ${({ $isDropped }) =>
    $isDropped &&
    `
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
      right: 0;
    `}
`;

const Ul = styled.ul`
  & > li {
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    line-height: 100%;
  }

  & > li:last-of-type {
    border-bottom: none;
  }

  //   background-color: ${colors.indigo};
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavbarLink = styled(NavLink)<{ isSelected?: boolean }>`
  width: 100%;
  padding: 16px;
  color: ${colors.graySix};
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  min-width: 148px;
  transition: all 0.15s ease-in;
  &:focus {
    color: white;
    background-color: ${colors.pointColorBlue};
    & path {
      fill: white;
    }
  }
  & path {
    fill: ${colors.graySix};
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: white;
      background-color: ${colors.pointColorBlue};
      & path {
        fill: white;
      }
    `}
`;

export default DropDown;
