import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LabeledInput } from "components/Input/Atom";
import { BaseModal } from "components/Modal/Atom";
import ModalPortal from "components/ModalPortal";
import LabeledTextarea from "components/Textarea/Atom/LabeledTextarea";
import { useAddESCCommand } from "hook/useAddECSCommand";
import { icons } from "modules/icons";
import { useRef } from "react";
import { FormatterProps } from "react-data-grid";
import { colors } from "style/theme";
import { TicketRow } from ".";
import BaseInstance from "instance/axios";

const EditModal = ({
  isOpen,
  setIsOpen,
  loadRecords,
  formatterProps,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadRecords: React.Dispatch<React.SetStateAction<boolean>>;
  formatterProps: FormatterProps<TicketRow, unknown>;
}) => {
  const formData = useRef<Map<string, any>>(new Map());

  useAddESCCommand(() => {
    setIsOpen(false);
  });

  const onSubmit = () => {
    const updatedRow = {
      ...formatterProps?.row,
      ...Object.fromEntries(formData.current?.entries()),
    };

    // const isEmptyExist = Object.values(updatedRow).some(
    //   (e) => e === undefined || e === ""
    // );

    const onReject = () => {
      alert("업데이트 못한다!");
    };

    const onConfirm = async () => {
      await BaseInstance.ticketsTicketIdPut(formatterProps?.row?.id, {
        plateCode: formData.current.get("plateCode"),
        customerName: formData.current.get("customerName"),
        mobile: formData.current.get("mobile"),
        siteId: Number(localStorage.getItem("siteId")),
        vinCode: formData.current.get("vinCode"),
        context: formData.current.get("context"),
        carModel: formData.current.get("carModel"),
      });

      loadRecords(true);
      setIsOpen(false);
      formatterProps?.onRowChange(updatedRow);
    };

    onConfirm();
    // isEmptyExist ? onReject() : onConfirm();
  };

  return isOpen ? (
    <ModalPortal>
      <BaseModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContentWrapper>
          <ModalHeader>
            <h2>접수 수정</h2>

            <div className="options">
              <div className="icon-box" onClick={() => setIsOpen(false)}>
                <icons.CloseIcon />
              </div>
            </div>
          </ModalHeader>

          <ModalFieldSet>
            <LabeledInput
              isFirstInput
              initialValue={formatterProps?.row.plateCode}
              htmlFor="plateCode"
              labelText="차량번호"
              notifier={(value) => {
                formData.current?.set("plateCode", value);
              }}
            />

            <LabeledInput
              htmlFor="carModel"
              initialValue={formatterProps?.row.carModel}
              labelText="모델"
              notifier={(value) => {
                formData.current?.set("carModel", value);
              }}
            />

            <LabeledInput
              htmlFor="vinCode"
              initialValue={formatterProps?.row.vinCode}
              labelText="VIN"
              notifier={(value) => {
                formData.current?.set("vinCode", value);
              }}
            />

            <ClientInfoBox>
              <LabeledInput
                htmlFor="customerName"
                initialValue={formatterProps?.row.customerName}
                labelText="고객명"
                additionalCSS={css`
                  width: 100%;
                `}
                notifier={(value) => {
                  formData.current?.set("customerName", value);
                }}
              />
              <LabeledInput
                htmlFor="mobile"
                initialValue={formatterProps?.row.mobile}
                labelText="전화번호"
                additionalCSS={css`
                  width: 100%;
                `}
                notifier={(value) => {
                  formData.current?.set("mobile", value);
                }}
              />
            </ClientInfoBox>

            <LabeledTextarea
              initialValue={formatterProps?.row.context}
              htmlFor="context"
              labelText="비고"
              placeholder="Type event details"
              notifier={(value) => {
                formData.current?.set("context", value);
              }}
            />
          </ModalFieldSet>

          <ModalConfirmBtnBox>
            <button className="cancel_btn" onClick={() => setIsOpen(false)}>
              취소
            </button>
            <button onClick={onSubmit}>저장</button>
          </ModalConfirmBtnBox>
        </ModalContentWrapper>
      </BaseModal>
    </ModalPortal>
  ) : (
    <></>
  );
};

export default EditModal;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & h2 {
    color: #eef0f4;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    word-wrap: break-word;
  }

  & .options {
    & .icon-box {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 26.5px;

  gap: 20px;
`;

const ClientInfoBox = styled.div`
  display: flex;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  & button {
    width: 54px;
    height: 40px;
    background-color: ${colors.pointColorBlue};
    color: white;
    border-radius: 6px;

    &.cancel_btn {
      background-color: white;
      color: ${colors.graySix};
    }
  }
`;
