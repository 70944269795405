import styled from "@emotion/styled";
import { createContext } from "react";
import Dashboards from "./Dashboards";

export const DashboardCTX = createContext<any>(null);
type DashboardProps = {
  title: string,
}

function Index({ title } : DashboardProps) {
  const contextValue = { test: "true" };

  return (
    <>
      <ContentBox>
        <ContentTitle>{title}</ContentTitle>

        <MainContent>
          <DashboardCTX.Provider value={contextValue}>
          <Dashboards />
          </DashboardCTX.Provider>
        </MainContent>
      </ContentBox>
    </>
  );
}

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ContentTitle = styled.h1`
  color: #eef0f4;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  word-wrap: break-word;
  margin: 30px 0;
`;

const MainContent = styled.div`
  flex: 1;
  @media (max-width: 1440px) {
    width: 100%;
    max-width: 85%;
  }

  @media (max-width: 575px) {
    max-width: unset;
  }
`;

export default Index;
