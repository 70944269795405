import { ReactComponent as DashboardLogo } from "images/svg/Dashboard_logo.svg";
import { ReactComponent as DashboardIcon } from "images/svg/dashboard_icon.svg";
import { ReactComponent as ReceptionsIcon } from "images/svg/receptions_icon.svg";
import { ReactComponent as ReceptionWaitingIcon } from "images/svg/receptionWatings_icon.svg";
import { ReactComponent as CentersIcon } from "images/svg/centers_icon.svg";
import { ReactComponent as UsersIcon } from "images/svg/users_icon.svg";
import { ReactComponent as ProjectsIcon } from "images/svg/projects_icon.svg";
import { ReactComponent as HamburgerIcon } from "images/svg/hamburger_icon.svg";
import { ReactComponent as SearchIcon } from "images/svg/search_icon.svg";
import { ReactComponent as PlusIcon } from "images/svg/plus_icon.svg";
import { ReactComponent as EmailIcon } from "images/svg/email_icon.svg";
import { ReactComponent as CloseIcon } from "images/svg/close_icon.svg";
import { ReactComponent as ArrowDownIcon } from "images/svg/arrowdown_icon.svg";
import { ReactComponent as EyeIcon } from "images/svg/eye_icon.svg";
import { ReactComponent as PencilIcon } from "images/svg/pencil_icon.svg";
import { ReactComponent as TrashIcon } from "images/svg/trash_icon.svg";
import { ReactComponent as DotIcon } from "images/svg/dot_icon.svg";
import { ReactComponent as IncrementIcon } from "images/svg/increment_icon.svg";
import { ReactComponent as PrintIcon } from "images/svg/print_icon.svg";
import { ReactComponent as ArrowDownIcons } from "images/svg/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "images/svg/arrow_up.svg";


export const icons = {
  ArrowUpIcon,
  ArrowDownIcons,
  DashboardLogo,
  DashboardIcon,
  ReceptionsIcon,
  ReceptionWaitingIcon,
  CentersIcon,
  UsersIcon,
  ProjectsIcon,
  HamburgerIcon,
  SearchIcon,
  PlusIcon,
  EmailIcon,
  CloseIcon,
  ArrowDownIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
  DotIcon,
  IncrementIcon,
  PrintIcon,
};
