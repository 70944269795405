import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { BaseTable } from "components/Table/Atom";
import dayjs from "dayjs";
import BaseInstance from "instance/axios";
import { icons } from "modules/icons";
import { useEffect, useState } from "react";
import type { Column } from "react-data-grid";
import { FormatterProps, SelectColumn } from "react-data-grid";
import { CustomUserResponse, UserRow } from ".";
import EditModal from "./EditModal";

interface Props {
  refreshUsers: (val: any) => any;
}

function UserTable(
  orders: any,
  page: number = 1,
  { refreshUsers }: Props,
  setShortColumn: any
) {
  const [refresh, setRefresh] = useState<{ type: "YES" | "NO" }>({
    type: "NO",
  });

  useEffect(() => {
    refreshUsers(refresh);
  }, [refresh, refreshUsers]);

  const columns: readonly Column<UserRow>[] = [
    {
      ...SelectColumn,
      width: "auto",
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "email",
      name: "아이디",
      sortable: true,
      resizable: true,
    },
    {
      key: "createdAt",
      name: "가입일자",
      resizable: true,
      cellClass: css`
        color: #dde1e8;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 700;
        word-wrap: break-word;
      `,
    },
    {
      key: "role",
      name: "포지션",
      sortable: true,
      resizable: true,
    },
    {
      key: "mobile",
      name: "핸드폰번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "exposedMobile",
      name: "노출 가능한 휴대폰 번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "siteName",
      name: "센터",
      sortable: true,
      resizable: true,
    },
    // {
    //   key: "workbayName",
    //   name: "워크베이",
    //   sortable: true,
    //   resizable: true,
    // },
    {
      key: "options",
      name: "options",
      sortable: true,
      resizable: true,
      headerCellClass: css`
        justify-content: flex-end;
      `,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter(formatterProps) {
        const deleteReceiptAction = async (tid: number) => {
          if (window.confirm("Delete this item?")) {
            deleteItem(tid);
          }
        };

        // delete item after confirm
        const deleteItem = async (deleteItemId: number) => {
          await BaseInstance.usersUserIdDelete(deleteItemId)
            .then(({ data }: any) => {
              setRefresh({ type: "YES" });
              getRow();
            })
            .catch((e) => {
              alert("Error! please try again");
              console.log("Tickets delete Error ::: ", e);
            });
        };
        return (
          <OptionBox>
            <OptionBtn>
              <icons.EyeIcon />
            </OptionBtn>

            <OptionBtn
              onClick={() => {
                setIsEditModalOpen(true);
                setFormatterProps(formatterProps);
              }}
            >
              <icons.PencilIcon />
            </OptionBtn>

            <OptionBtn
              onClick={() => {
                setRefresh({ type: "NO" });
                deleteReceiptAction(formatterProps?.row?.id);
              }}
            >
              <icons.TrashIcon />
            </OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  useEffect(() => {
    setRows(orders);
  }, [orders]);

  const [sortColumn, setSortColumn] = useState<any>(null);
  const [rows, setRows] = useState<any>(); // code changes for ticket #564
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isReceptionInformOpen, setIsReceptionInformOpen] = useState(false);
  const [users, setUsers] = useState<Array<UserRow>>([]);

  const [formatterProps, setFormatterProps] = useState<
    FormatterProps<UserRow, unknown> | undefined
  >();

  const getRow = () => {
    BaseInstance.usersGet(
      {},
      {
        params: {
          query: JSON.stringify({
            siteId: Number(localStorage.getItem("siteId")),
          }),
          populate: ['role', 'site', 'workbay'],
          sort: '-createdAt',
          limit: 1000,
          pageSize: 1000,
        },
      }
    )
      .then((res) => res.data.rows ?? [])
      .then((data: CustomUserResponse[]) => {
        setUsers(
          data.map((x: CustomUserResponse) => {
            return {
              id: x.id,
              email: x.email,
              role: x.role,
              siteId: x.siteId,
              name: x.name,
              roleName: x.role,
              mobile: x.mobile,
              workbayName: x.workbay?.name,
              workbayId: x.workbay?.id,
              siteName: x.site?.name,
              exposedMobile: x.exposedMobile,
              createdAt: dayjs(x?.createdAt).format("YY-MM-DD HH:mm"),
            };
          })
        );
      });
  };

  return {
    selectedRows,
    Table: () => (
      <>
        <BaseTable
          columns={columns}
          rows={rows}
          rowKeyGetter={(row: UserRow) => row.id}
          onRowsChange={rows}
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          sortColumns={sortColumn}
        />
        <EditModal
          formatterProps={formatterProps as FormatterProps<UserRow, unknown>}
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          loadRecords={(value) => {
            setRefresh({ type: "YES" });
          }}
        />
      </>
    ),
  };
}

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 17px;
    height: 20px;
  }
  @media (max-width: 1240px) {
    width: 20px;
    height: 20px;
  }
  @media (max-width: 768px) {

    width: 17px;
    height: 20px;
  }
`;

export default UserTable;
