import styled from "@emotion/styled";

interface FileSaveButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}
export const FileButton = ({
  children,
  onClick,
  disabled,
}: FileSaveButtonProps) => {
  return (
    <StyledFileButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledFileButton>
  );
};

const StyledFileButton = styled.button`
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 3px;
  font-weight: 700;
  background: var(--Primary-700, #0077e4);
  color: #ffffff;
  &:disabled {
    opacity: 0.3;
    background-color: #75a5d2;
  }
`;
