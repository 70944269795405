import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { BaseButton } from "components/Button/Atom";
import { icons } from "modules/icons";
import { useEffect, useState } from "react";
import { colors } from "style/theme";

interface Props {
  defaultValue?: string;
  initialKey?: string | number;
  labelText: string;
  items: { key: number | string; value: string }[];
  additionalCSS: SerializedStyles;
  notifier: (...args: any) => any;
  disabled?: boolean;
}

const DisabledPrintBtnCSS = css`
  background-color: #e0e0e0;
  border-color: #a9a9a9;
  color: #a9a9a9;
  cursor: not-allowed;
`;

function KeyValueDropdown({
  initialKey,
  labelText,
  additionalCSS,
  items,
  notifier,
  disabled,
}: Partial<Props>) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(initialKey);

  const [selectedValue, setSelectedValue] = useState<string | undefined>();

  useEffect(() => {
    notifier?.(selectedKey);
    setSelectedValue(
      selectedKey ? items?.find((item) => item.key === selectedKey)?.value : ""
    );
  }, [items, notifier, selectedKey]);

  return (
    <Container>
      {labelText && <Label style={{ color: disabled ? '#a9a9a9' : undefined }}>{labelText}</Label>}
      <Button
        additionalCSS={disabled ? DisabledPrintBtnCSS : additionalCSS}
        onClick={() => disabled ? {} : setIsDropdownOpen((prev) => !prev)}
      >
        <p>{selectedValue}</p>
        <icons.ArrowDownIcon />

        <Dropdown
          isDropdownOpen={isDropdownOpen}
          onClick={(e) => e.stopPropagation()}
        >
          {items?.map((item) => (
            <ItemList
              key={item.key}
              value={item.value}
              onClick={() => {
                setSelectedKey(item.key);
                setSelectedValue(item.value);
                setIsDropdownOpen(false);
                notifier?.(item.key);
              }}
            >
              {item.value}
            </ItemList>
          ))}
        </Dropdown>
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Label = styled.label`
  color: #eef0f4;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;
`;

const Button = styled(BaseButton)`
  width: 100%;
  height: 40px;
  background-color: ${colors.pointColorGray};
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

const Dropdown = styled.ul<{ isDropdownOpen: boolean }>`
  position: absolute;
  width: 100%;
  min-height: 100%;
  height: auto;
  left: 0;
  top: 110%;
  z-index: var(--zIndex-1st);
  background-color: ${colors.pointColorGray};
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transition: all 0.2s ease-in;
  transform-origin: top;

  ${({ isDropdownOpen }) =>
    isDropdownOpen &&
    css`
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    `}
`;
const ItemList = styled.li`
  width: 100%;
  height: auto;
  display: flex;
`;

export default KeyValueDropdown;
