import axios, { AxiosError } from "axios";
import { authEnum } from "config/auth";
import { FormatterProps } from "react-data-grid";
import { TicketRow } from "../..";
import { AddedFileProps, FileModalForm } from "./FileModalForm";

const FileModal = ({
  formatterProps,
  onClose,
}: {
  onClose: () => void;
  formatterProps: FormatterProps<TicketRow, unknown>;
}) => {
  const onSave = async (files: AddedFileProps[]) => {
    const formData = new FormData();

    Array.from(files).forEach((file: any) => {
      formData.append("file", file);
    });

    const sizePlus = files.reduce((total, file) => total + file.size, 0);

    if (sizePlus <= 10 * 1024 * 1024) {
      await axios({
        method: "post",
        url: `https://mbk.sizl.co.kr/api/ticket/${formatterProps.row.id}/attachment`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(authEnum.authToken)}`,
        },
      })
        .then((result: any) => {
          if (result.status === 200) {
            alert("업로드에 성공하셨습니다.");
            onClose();
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
    } else {
      alert("파일 용량이 초과되었습니다.");
    }
  };

  return (
    <FileModalForm
      onClose={onClose}
      ticketId={formatterProps.row.id}
      onSave={onSave}
    />
  );
};

export default FileModal;
