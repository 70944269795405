import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { authEnum } from "config/auth";
import BaseInstance from "instance/axios";
import { icons } from "modules/icons";
import { menuList } from "modules/sidebar";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { colors } from "style/theme";

function Index(showSideBar: any) {
  const location = useLocation();
  const [menus, setMenus] = useState(menuList);

  useEffect(() => {
    getInfo();
  }, [location]);

  const getInfo = async () => {
    await BaseInstance.whoamiGet({
      headers: {
        Authorization: `Bearer ${localStorage.getItem(authEnum.authToken)}`,
      },
    }).then(({ data }: any) => {
      if (data?.role !== "ADMIN") {
        const filteredMenus: any = menuList.filter((object) => {
          return object.id !== "/users";
        });
        setMenus(filteredMenus);
      }
    });
  };

  return (
    <Aside isShowSideBar={showSideBar.showSideBar}>
      <AsideLogoBox>
        <icons.DashboardLogo />
      </AsideLogoBox>
      <MenuUl>
        {menus.map((menu) => (
          <NavbarLink
            key={menu.id}
            isSelected={menu.id === location.pathname}
            to={menu.id}
          >
            {menu.icon}
            {menu.name}
          </NavbarLink>
        ))}
      </MenuUl>
    </Aside>
  );
}

const NavbarLink = styled(NavLink) <{ isSelected?: boolean }>`
  width: 100%;
  padding: 16px;
  color: ${colors.graySix};
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  min-width: 148px;
  transition: all 0.15s ease-in;
  &:focus {
    color: white;
    background-color: ${colors.pointColorBlue};
    & path {
      fill: white;
    }
  }
  & path {
    fill: ${colors.graySix};
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: white;
      background-color: ${colors.pointColorBlue};
      & path {
        fill: white;
      }
    `}
`;
const Aside = styled.aside<{ isShowSideBar?: boolean }>`
  width: 208px;
  height: auto;
  min-height: 100vh;
  background-color: ${colors.indigo};

  padding: 20px 16px 0px;
  box-shadow: 0px 4px 20px rgba(6.82, 6.02, 18.06, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @media (max-width: 430px) {
    ${({ isShowSideBar }) =>
    !isShowSideBar &&
    css`
        display: none;
      `}
`;
const AsideLogoBox = styled.div``;
const MenuUl = styled.ul`
  width: 100%;
  margin-top: 25px;
`;

export default Index;
