import styled from "@emotion/styled";
import { createContext, useState } from "react";

import { css } from "@emotion/react";
import { BaseButtonTab } from "components/Button/Atom";
import Receptions from "./Reception";
type ReceptionsProps = {
  title: string;
};

export const ReceptionsCTX = createContext<any>(null);

const checkedButtonColor = css`
  background-color: #f2f2f2;
  color: #000;
`

const filterList = {
  waiting: "WAITING",
  inprogress: "IN_PROGRESS",
  pending: "PENDING",
  done: "DONE",
  all: "ALL",
};

function Index({ title }: ReceptionsProps) {
  const contextValue = { test: "true" };
  const [progress, setProgress] = useState("ALL");

  return (
    <>
      <ContentBox>
        <OrderBar>
          <ContentTitle>{title}</ContentTitle>
          <BaseContent>
            <BaseButtonTab
              additionalCSS={progress === filterList.all ? checkedButtonColor : undefined}
              onClick={() => {
                setProgress(filterList.all);
              }}
            >
              전체
            </BaseButtonTab>
            <BaseButtonTab
              additionalCSS={progress === filterList.waiting ? checkedButtonColor : undefined}
              onClick={() => {
                setProgress(filterList.waiting);
              }}
            >
              대기
            </BaseButtonTab>
            <BaseButtonTab
              additionalCSS={progress === filterList.inprogress ? checkedButtonColor : undefined}
              onClick={() => {
                setProgress(filterList.inprogress);
              }}
            >
              진행중
            </BaseButtonTab>
            <BaseButtonTab
              additionalCSS={progress === filterList.pending ? checkedButtonColor : undefined}
              onClick={() => {
                setProgress(filterList.pending);
              }}
            >
              작업지연
            </BaseButtonTab>
            <BaseButtonTab
              additionalCSS={progress === filterList.done ? checkedButtonColor : undefined}
              onClick={() => {
                setProgress(filterList.done);
              }}
            >
              완료
            </BaseButtonTab>
          </BaseContent>
        </OrderBar>

        <MainContent>
          <ReceptionsCTX.Provider value={contextValue}>
            <Receptions progress={progress} />
          </ReceptionsCTX.Provider>
        </MainContent>
      </ContentBox>
    </>
  );
}

const OrderBar = styled.div`
  margin-top: 24px;
  padding: 0 24px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1240px) {
    width: 94%;
  }
  @media (max-width: 1024px) {
    width: 94%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    h1 {
      margin-bottom: 0;
    }
  }
`;
const BaseContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  @media (max-width: 575px) {
    width: 100%;
    max-width: 420px;
  }
  @media (max-width: 430px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;
const Container = styled.div`
  width: 100%;
  height: auto;

  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 20px 23px;
  display: flex;
  gap: 17px;
`;

const ContentSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ContentTitle = styled.h1`
  color: #eef0f4;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  word-wrap: break-word;
  margin: 30px 0;
`;

const MainContent = styled.div`
  flex: 1;
  @media (max-width: 1240px) {
    width: 92%;
  }
  @media (max-width: 1024px) {
    width: 92%;
  }
  @media (max-width: 1336px) {
    width: 98%;
  }
  @media (max-width: 430px) {
    width: 120%;
  }
  @media (max-width: 575px) {
    width: 100%;
    max-width: 452px;
  }
`;

export default Index;
