import styled from "@emotion/styled";
import { createContext} from "react";

import Projects from "./Siteonfig/SiteConfig";
type ProjectsProps = {
  title: string,
}
export const ProjectsCTX = createContext<any>(null);
function Index({ title } : ProjectsProps) {
  const contextValue = { test: "true" };

  return (
    <>
      <ContentBox>
        <ContentTitle>{title}</ContentTitle>

        <MainContent>
          <ProjectsCTX.Provider value={contextValue}>
          <Projects />
          </ProjectsCTX.Provider>
        </MainContent>
      </ContentBox>
    </>
  );
}

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ContentTitle = styled.h1`
  color: #eef0f4;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  word-wrap: break-word;
  margin: 30px 0;
`;

const MainContent = styled.div`
  flex: 1;
`;

export default Index;
