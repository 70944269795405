import styled from "@emotion/styled";
import { useLogout } from "hook/useLogout";
import { icons } from "modules/icons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { colors } from "style/theme";
import { useCheckAuth } from "utils/checkAuth";
import DropDown from "./DropDown";

function Index({ setHideSideBAr }: any) {
  const isLogin = useCheckAuth();
  const logout = useLogout();
  const [showSideBar, setShowSideBar] = useState<any>(false);
  const location = useLocation();

  const hideFunc = (data: any) => {
    setShowSideBar(data);
    setHideSideBAr(data);
  };
  useEffect(() => {
    if (location?.pathname) {
      setHideSideBAr(false);
      setShowSideBar(false);
    }
  }, [location, setHideSideBAr]);

  return (
    <Header>
      <MenuSearchWrapper>
        <MenuBox>
          {/* {showSideBar ? (
            <div onClick={() => hideFunc(false)}>
              <icons.HamburgerIcon />
            </div>
          ) : (
            <div onClick={() => hideFunc(true)}>
              <icons.HamburgerIcon />
            </div>
          )} */}
          <DropDown>
            <icons.HamburgerIcon />
          </DropDown>
        </MenuBox>
        <SearchBox>
          <icons.SearchIcon />
          <SearchInput placeholder="Search here.." />
        </SearchBox>
        <AuthBox isLogin={isLogin}>
          <button onClick={logout}>로그아웃</button>
        </AuthBox>
      </MenuSearchWrapper>

      <OptionBox></OptionBox>
    </Header>
  );
}

const Header = styled.header`
  width: 100%;
  height: 80px;
  background-color: ${colors.indigo};
  padding: 20px 24px;
  box-shadow: 0px 4px 20px rgba(6.82, 6.02, 18.06, 0.1);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 575px) {
    width: 100%;
    max-width: 85%;
  }

  @media (max-width: 430px) {
    width: 100%;
    max-width: 98%;
  }
`;
const MenuSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const MenuBox = styled.div`
  cursor: pointer;
`;
const SearchBox = styled.div`
  width: 240px;
  height: 40px;
  padding: 10px;
  background: #3b4758;
  color: #bac4d1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  @media (max-width: 575px) {
    width: 100%;
  }

  @media (max-width: 430px) {
    min-width: unset;
  }
`;
const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  background: #3b4758;
  color: #bac4d1;
  border: none;

  &::-webkit-input-placeholder {
    color: #bac4d1;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word;
  }
`;
const OptionBox = styled.div``;

const AuthBox = styled.div<{ isLogin: boolean }>`
  display: ${({ isLogin }) => (isLogin ? "block" : "none")};

  & button {
    color: white;
    border-radius: 0;
    padding: 0.7rem;
    color: white;
    font-size: 3.2rem;
    font-family: MBK CorpoS;
    font-weight: 400;
    letter-spacing: 0.032rem;
    word-wrap: break-word;
    @media (max-width: 575px) {
      font-size: 1.5rem;
    }
  }
`;

export default Index;
