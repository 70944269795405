import styled from "@emotion/styled";
import { KeyValueDropdown } from "components/Dropdown/Atom";
import { LabeledInput } from "components/Input/Atom";
import { BaseModal } from "components/Modal/Atom";
import ModalPortal from "components/ModalPortal";
import { useState } from "react";

import { WorkbayResponse } from "@sizlcorp/mbk-api-document/dist/models";
import { useAddESCCommand } from "hook/useAddECSCommand";
import BaseInstance from "instance/axios";
import { icons } from "modules/icons";
import { useEffect, useRef } from "react";
import { FormatterProps } from "react-data-grid";
import { colors } from "style/theme";
import { UserRow } from ".";

const EditModal = ({
  isOpen,
  setIsOpen,
  loadRecords,
  formatterProps,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadRecords: React.Dispatch<React.SetStateAction<boolean>>;
  formatterProps: FormatterProps<UserRow, unknown>;
}) => {
  const formData = useRef<Map<string, any>>(new Map());
  const [workbays, setWorkbays] = useState<{ key: number; value: string }[]>(
    []
  );
  const roles: { key: string; value: string }[] = [
    { key: "ADMIN", value: "관리자" },
    { key: "TECHNICIAN", value: "테크니션" },
    { key: "ADVISER", value: "어드바이저" },
  ];
  useAddESCCommand(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    BaseInstance.workbaysGet({}).then((res) => {
      if (!res.data.rows || res.data.rows.length === 0) return;
      setWorkbays(
        res.data.rows.map((model: WorkbayResponse) => {
          return {
            key: model.id,
            value: model.name as string,
          };
        })
      );
    });
  }, []);
  const onSubmit = () => {
    const updatedRow = {
      ...formatterProps?.row,
      ...Object.fromEntries(formData.current?.entries()),
    };

    const onConfirm = async () => {
      await BaseInstance.usersUserIdPut(formatterProps?.row?.id, {
        siteId: Number(localStorage.getItem("siteId")),
        email: formData.current?.get("email"),
        name: formData.current?.get("name"),
        mobile: formData.current?.get("mobile"),
        role: formData.current?.get("role"),
        password: formData.current?.get("password"),
        exposedMobile: formData.current?.get("exposedMobile"),
        // defaultWorkbayId: formData.current?.get("defaultWorkbayId"),
      });

      loadRecords(true);
      setIsOpen(false);
      formatterProps?.onRowChange(updatedRow);
      BaseInstance.usersGet(
        {},
        {
          params: {
            query: JSON.stringify({
              siteId: Number(localStorage.getItem("siteId")),
            }),
            populate: ["role", "site", "workbay"],
            sort: "-createdAt",
            limit: 1000,
            pageSize: 1000,
          },
        }
      ).then((res) => {
        console.log(res, "ressss");
      });
    };

    onConfirm();
  };


  return isOpen ? (
    <ModalPortal>
      <BaseModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContentWrapper>
          <ModalHeader>
            <h2>사용자 추가</h2>

            <div className="options">
              <div className="icon-box" onClick={() => setIsOpen(false)}>
                <icons.CloseIcon />
              </div>
            </div>
          </ModalHeader>

          <ModalFieldSet>
            <LabeledInput
              isFirstInput
              initialValue={formatterProps?.row.email}
              htmlFor="email"
              labelText="사용자명(이메일)"
              notifier={(value) => {
                formData.current?.set("email", value);
              }}
            />

            <LabeledInput
              htmlFor="name"
              initialValue={formatterProps?.row.name}
              labelText="이름"
              notifier={(value) => {
                formData.current?.set("name", value);
              }}
            />

            <LabeledInput
              htmlFor="mobile"
              initialValue={formatterProps?.row.mobile}
              labelText="핸드폰번호"
              notifier={(value) => {
                formData.current?.set("mobile", value);
              }}
            />
            <LabeledInput
              htmlFor="exposedMobile"
              initialValue={formatterProps?.row.exposedMobile}
              labelText="노출모바일"
              notifier={(value) => {
                formData.current?.set("exposedMobile", value);
              }}
            />

            <KeyValueDropdown
              disabled={localStorage.getItem("role") !== "ADMIN"}
              labelText="포지션"
              initialKey={formatterProps?.row.role}
              items={roles}
              notifier={(value) => {
                formData.current?.set("role", value);
              }}
            />

            {/* <KeyValueDropdown
              labelText="워크베이"
              initialKey={formatterProps?.row.workbayId}
              items={workbays}
              notifier={(value) => {
                formData.current?.set("defaultWorkbayId", value);
              }}
            /> */}

            <LabeledInput
              htmlFor="password"
              labelText="패스워드"
              notifier={(value) => {
                formData.current?.set("password", value);
              }}
            />

            <LabeledInput
              htmlFor="password-check"
              labelText="패스워드 확인"
              notifier={(value) => {
                formData.current?.set("passwordCheck", value);
              }}
            />
          </ModalFieldSet>

          <ModalConfirmBtnBox>
            <button onClick={onSubmit}>추가</button>
          </ModalConfirmBtnBox>
        </ModalContentWrapper>
      </BaseModal>
    </ModalPortal>
  ) : (
    <></>
  );
};

export default EditModal;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & h2 {
    color: #eef0f4;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    word-wrap: break-word;
  }

  & .options {
    & .icon-box {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 26.5px;

  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;

  & button {
    width: 54px;
    height: 40px;
    background-color: ${colors.pointColorBlue};
    color: white;
    border-radius: 6px;
  }
`;
