import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { HTMLAttributes, PropsWithChildren } from "react";
import { colors } from "style/theme";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  additionalCSS?: SerializedStyles;
}

function BaseButtonTab({
  children,
  additionalCSS,
  ...attrs
}: PropsWithChildren<Props>) {
  return (
    <Button additionalCSS={additionalCSS} {...attrs}>
      {children}
    </Button>
  );
}

const Button = styled.button<Props>`
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  border-color: ${colors.pointColorGreen};

  color: white;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  border: 1px solid;
  padding: 0px 4px;
  @media (max-width: 375px) {
    width: 129px;
  }

  ${({ additionalCSS }) => additionalCSS && additionalCSS};
`;

export default BaseButtonTab;
