import styled from "@emotion/styled";

interface FileDropZoneTitleProps {
  rightSection?: React.ReactNode;
  leftSection?: React.ReactNode;
  children: React.ReactNode;
}

export const FileDropZoneTitle = ({
  children,
  leftSection,
  rightSection,
}: FileDropZoneTitleProps) => {
  return (
    <Title>
      {leftSection && <div>{leftSection}</div>}
      {children}
      {rightSection && <div>{rightSection}</div>}
    </Title>
  );
};

const Title = styled.div`
  display: flex;
  color: #ffffff;
  align-items: center;
  gap: 5px;
`;
